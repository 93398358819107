:root {
    /* Team System design system */
    --brand-primary: #0090d1;
    --brand-primary-shade-medium: #00c3ea;
    --brand-primary-shade-dark: #005075;
    --brand-primary-shade-light: #b9e6f6;
    --grey-01: #c9d9e8;
    --grey-02: #def0f7;
    --grey-03: #f2f5f8;
    --white: #ffffff;
    --text-01: #152935;
    --text-02: #5a6872;
    --text-03: #9da6ad;
    --text-04: #c1c1c4;
    --text-05: #d8d8d9;
    --status-info: var(--brand-primary-shade-dark);
    --status-ok: #92cf72;
    --status-pending: #f9ce3d;
    --status-alert: #fc4e3d;
}
