.arc-ContentSwitcher-container {
    display: flex;
    width: 100%;
}

.arc-ContentSwitcher-switch-container {
    /* set flexible items to the same lenght */
    flex: 1;
}

.arc-ContentSwitcher-switch-container:first-child > button {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.arc-ContentSwitcher-switch-container:last-child > button {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.arc-ContentSwitcher-switch {
    width: 100%;
    font-family: Cairo;
    font-size: 14px;
    color: var(--text-02);
    height: 40px;
    cursor: pointer;
    background-color: var(--grey-03);
    border: none;
}

.arc-ContentSwitcher-switch:hover {
    border: solid 1px var(--brand-primary);
    color: var(--brand-primary);
}

/* replace standard browser outline with rounded border */
.arc-ContentSwitcher-switch:focus {
    outline: none;
    background: linear-gradient(var(--grey-03), var(--grey-03)) padding-box,
        linear-gradient(
                311deg,
                var(--brand-primary-shade-medium),
                var(--brand-primary)
            )
            border-box;

    border: 4px solid transparent;
}

.arc-ContentSwitcher-switch.arc-ContentSwitcher-switch-selected {
    background: linear-gradient(
            311deg,
            var(--brand-primary-shade-medium),
            var(--brand-primary)
        )
        border-box;
    color: var(--white);
}

.arc-ContentSwitcher-switch.arc-ContentSwitcher-switch-selected:hover {
    border: none;
    background: var(--brand-primary-shade-medium);
    color: var(--white);
}

/* replace standard browser outline with rounded border */
.arc-ContentSwitcher-switch.arc-ContentSwitcher-switch-selected:focus {
    outline: none;
    background: linear-gradient(
                311deg,
                var(--brand-primary-shade-medium),
                var(--brand-primary)
            )
            border-box,
        linear-gradient(
                311deg,
                var(--brand-primary-shade-medium),
                var(--brand-primary)
            )
            border-box;
    border: 4px solid transparent;
}

.arc-ContentSwitcher-switch.arc-ContentSwitcher-switch-selected:hover:focus {
    border: none;
    background: var(--brand-primary-shade-medium);
    color: var(--white);
    outline: none;
}
